import React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'

import './index.css'

import store from '@/store'
import router from '@/router'

createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <React.StrictMode>
       <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
)
