import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
  session: string | null
}

const initialState: AppState = {
  session: null
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<string>) => {
      state.session = action.payload
    }
  }
})

export const { setSession } = appSlice.actions

export default appSlice.reducer
