import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from '@/components/Header'
import Footer from '@/components/Footer'

const IndexLayout: React.FC = () => {
  return (
    <>
      <Header />
      <main className={'container w-full md:max-w-4xl px-4 md:px-6  mx-auto pt-20 pb-10'}>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default IndexLayout
