import { combineReducers, configureStore } from '@reduxjs/toolkit'

import appReducer from '@/store/reducers/app'

export const store = configureStore({
  reducer: combineReducers({
    app: appReducer
  })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
