import React from 'react'

const FooterComponent: React.FC = () => {
  return (
    <footer className={'bg-white border-t-2'}>
      <div className={'container max-w-4xl mx-auto p-8 text-center'}>
        <p className={'text-gray-600 text-sm'}>Badgix &copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  )
}

export default FooterComponent
