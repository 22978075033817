import React from 'react'
import { Link } from 'react-router-dom'

const HeaderComponent: React.FC = () => {
  return (
    <header className={'fixed w-full z-10 top-0 bg-white border-b-2'}>
      <div className={'w-full md:max-w-4xl mx-auto flex flex-wrap items-center justify-between mt-0 py-3'}>
        <div className={'pl-4'}>
          <Link to={`/`} className={'text-gray-900 text-base no-underline hover:no-underline font-extrabold text-xl'}>
            Badgix
          </Link>
        </div>
        <nav className={'w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 md:bg-transparent z-20 bg-white'}>
          <ul className='list-reset lg:flex justify-end flex-1 items-center'>
            <li className='mr-3'>
              <Link to={`/posts`} className={'inline-block text-gray-600 no-underline hover:text-gray-900 hover:text-underline py-2 px-4'}>Posts</Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default HeaderComponent
