import React from 'react'
import { createBrowserRouter } from 'react-router-dom'

import posts, { Post, Content } from '@/virtual/posts'

import IndexLayout from '@/layouts/Index'

const withLoader = (Component: React.ElementType) => (props: any) => {
  return (
    <React.Suspense fallback={<h2>Loading</h2>}>
      <Component {...props} />
    </React.Suspense>
  )
}

const IndexPage = withLoader(React.lazy(() => import('@/pages/Index')))
const PostsPage = withLoader(React.lazy(() => import('@/pages/Posts')))
const PostPage = withLoader(React.lazy(() => import('@/pages/Post')))

const router = createBrowserRouter([
  {
    path: '/',
    element: <IndexLayout />,
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: '/posts',
        children: [
          {
            index: true,
            element: <PostsPage />
          },
          {
            path: ':slug',
            element: <PostPage />,
            loader: async ({ params }): Promise<Post & Content> => {
              if (typeof params.slug !== 'undefined' && posts.hasOwnProperty(params.slug)) {
                const post: Post = posts[params.slug]

                const request = await fetch(post.url)
                const content: string[] = await request.json()

                return { ...post, content }
              }

              throw new Response('Not Found', {
                status: 404
              })
            }
          }
        ]
      }
    ]
  }
])

export default router
